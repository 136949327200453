import { useOFetch } from "~/composables/useOFetch";
import type { IApiResponseMulti } from "@/assets/ts/entities/api/interfaces/IApiResponseMulti";
import { api } from "~/api/api";
import type { IApiResponsePayload } from "@/assets/ts/entities/api/interfaces/IApiResponsePayload";
import { filterAcceptedEvents } from "~/utils/filter-accepted-events";
import type { IFetchError } from "ofetch";

export async function fetchMultiData(agentId: string = ""): Promise<{ error: Ref<string> }> {
	const {
		eventsStore: { setEvents },
		messagesStore: { setMessages },
		lastMessageStore: { readLastMessage },
		activeChatStore,
	} = useStores();

	const { getActiveChat } = storeToRefs(activeChatStore);

	const error: Ref<string> = ref("");

	try {
		const multiData: IApiResponsePayload<IApiResponseMulti> = await useOFetch<IApiResponsePayload<IApiResponseMulti>>(api.multiDetail(agentId || getActiveChat.value?.agent), {
			method: "GET",
		});
		const { events, messages } = multiData.data;

		await setMessages(messages);
		await readLastMessage();

		setEvents(filterAcceptedEvents(events));
	} catch (e) {
		const fetchError = e as IFetchError;

		error.value = fetchError.data?.message ?? fetchError.message ?? "Ошибка мульти запроса";
	}

	return { error };
}
